<template>
    <div class="customer__form modal modal--default">
      <div class="modal__panel">
        <div class="modal__logo-wrapper">
          <div class="modal__logo"></div>
          <div class="modal__logo-title">The One System</div>
        </div>

        <div v-if="this.filesEditMode == 'Calls'" class="modal__main-icon modal__main-icon--calls"></div> 
        <div v-if="this.filesEditMode == 'Pendencies'" class="modal__main-icon modal__main-icon--pendencies"></div>
        <div v-if="this.filesEditMode == 'Notes'" class="modal__main-icon modal__main-icon--notes"></div>
         
        <div class="modal__close" @click="closeModal()"></div>
  
         
        <div class="modal__title">
            {{filesEditMode}} Files 
        </div> 
  
        <div class="content">
          <div class="files">
            <div class="files__header">
              <div class="files__label files__label--large">Name</div>
              <div class="files__label files__label--small">View</div>
              <div class="files__label files__label--medium">Date</div> 
              <div class="files__label files__label--small">App</div>
              <div class="files__label files__label--small">Delete</div>
            </div>
            <div class="files__body">
              <Loading v-if="filesLoading" />
              <template v-else>
                <File
                  v-for="file in filesToEdit"
                  :key="file.id"
                  :file="file"
                  :updateFiles="updateFiles"
                />
              </template>
            </div>
          </div>
          <DropFile @input="uploadFiles($event)" :deletable="false" :bus="bus" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import File from "@/components/AccountDetails/File";
  import DropFile from "@/components/DropFile";
  import Vue from "vue";
  import Loading from "@/partials/Loading";
  export default {
    name: "AllFilesModal",
  
    components: {
      File,
      DropFile,
      Loading,
    },
  
    data() {
      return {
        files: [],
        bus: new Vue(),
        filesLoading: false,

     
      };
    },
  
    computed: {
      ...mapGetters([
        "filesEditMode",

        "filesToEdit",
        "filesToEditId",
        "customer",

        'filesFromDashboard',
        'customerFromDashboard',
      ]),
    },
  
    destroyed() {
      this.closeModal(); 
    },

 
  
    methods: {
      closeModal() { 
        this.$store.commit("setShowFilesEditModal", false);
        this.$store.commit("setFilesToEditId", null);
        this.$store.commit("setFilesToEdit", []); 
        this.$store.commit("setFilesEditMode", null); 

        this.$store.commit("setFilesFromDashboard", false);
        this.$store.commit("setCustomerFromDashboard", null);
      },
  
      async uploadFiles(event) {
        this.filesLoading = true;
        this.files = event;
  
        const files = this.files.map((file) => file.file);
        if(this.filesEditMode == 'Pendencies'){
          await this.$store.dispatch("addPendencyFile", {
            pendencyId: this.filesToEditId,
            files,
          });
        }else if(this.filesEditMode == 'Notes'){
          await this.$store.dispatch("addNotesFile", {
            noteId: this.filesToEditId,
            files,
          });
        }else if(this.filesEditMode == 'Calls'){
          await this.$store.dispatch("addCallsFile", {
            callId: this.filesToEditId,
            files,
          });
        }
        await this.updateFiles();
        this.bus.$emit("delete");
        this.filesLoading = false;
      },
  
      async updateFiles() { 
        if(this.filesEditMode == 'Pendencies'){
          await this.$store.dispatch("fetchPendencyFiles", this.filesToEditId); 
          this.refreshPendencies();

        } else if(this.filesEditMode == 'Notes'){
          await this.$store.dispatch("fetchNoteFiles", this.filesToEditId); 
          this.refreshNotes();
          
        } else if(this.filesEditMode == 'Calls'){
          await this.$store.dispatch("fetchCallsFiles", this.filesToEditId); 
          this.refreshCalls();
        }
      },

      async refreshPendencies(){
        console.log(this.$route.path);

        let customerId = null;
        if(this.filesFromDashboard){
          customerId = this.customerFromDashboard;
        }else{
          customerId = this.$route.params.id;
        }
 
        try {
          await this.$store.dispatch("getPendencies", customerId);
        } catch (error) {
          console.log('Error fetching pendencies:', error);
        } 

        if(this.$route.path == '/dashboard'){
          try {
            await this.$store.dispatch("getSalesDashboard");
          } catch (error) {
            console.log('Error fetching dashboard details:', error);
          }
        }

        this.$store.commit("setFilesFromDashboard", false);
        this.$store.commit("setCustomerFromDashboard", null);
      },

      async refreshNotes(){
        try {
          await this.$store.dispatch("getNotes", this.$route.params.id);
        } catch (error) {
          console.log('Error fetching notes:', error);
        } 
      },

      async refreshCalls(){
        try {
          await this.$store.dispatch("getCalls", this.$route.params.id);
        } catch (error) {
          console.log('Error fetching calls:', error);
        } 
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .modal {
    &__title {
      margin: 53px 0 35px 10px;
      font-size: var(--medium);
      font-family: var(--muli-text-semi-bold);
    }
  
    .modal__title,
    .files__label,
    ::v-deep.file {
      color: var(--text-2);
    }
  
    .content {
      display: flex;
      align-items: flex-start;
      height: calc(100% - 53px - 35px - 18px);
    }
  
    .files {
      width: 100%;
      height: 100%;
      margin-right: 20px;
      font-size: var(--x-small);
  
      &__header {
        border-bottom: 1px solid var(--border-10);
        display: flex;
        padding: 5px 10px;
        margin-bottom: 8px;
      }
  
      &__body {
        height: calc(100% - 25px - 8px);
        overflow: auto;
        position: relative;
      }
  
      ::v-deep.file {
        display: flex;
        padding: 20px 10px;
        background: var(--bg-3);
        margin-bottom: 1px;
        position: relative;
        height: 64px;
        max-height: 64px;
        transition: all 0.3s ease;
  
        &__data {
          &--large {
            color: var(--text-10);
            text-decoration: underline;
          }
        }
  
        .icon {
          background-position: left;
        }
      }
  
      &__label,
      ::v-deep.file__data {
        display: flex;
        align-items: center;
        &--large {
          flex: 3;
        }
        &--medium {
          flex: 2;
        }
        &--small {
          flex: 1;
        }
      }
    }
  }
  </style>
  