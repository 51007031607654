import api from "@/api";

export default{
    state:{
        calls: [],
    },
    getters:{
        calls: (state) => state.calls,
    },
    mutations:{
        setCalls: (state, data) => {
            state.calls = data;
        },
    },
    actions:{
        getCalls: async({commit}, id) =>{
            try{
                const response = await api({requiresAuth: true}).get(
                    `v1/customer-calls/${id}`
                );
                const {data} = response.data;
                commit("setCalls", data);
                return data;
            }catch(err){
                console.log(err);
                throw err;
            }
        },


        createCalls: async (
            { dispatch },
            {
                agent_id,
                customer_id,
                call_category_id,
                date,
                time,
                description,
                duration_minutes,
            }
        ) => {
            try {
                const response = await api({ requiresAuth: true }).post("v1/calls", {
                    agent_id,
                    customer_id,
                    call_category_id,
                    date,
                    time,
                    description,
                    duration_minutes,
                });

                const newCall = response.data.data;
                const callId = newCall.id;


                await dispatch("getCalls", customer_id);

                return callId;
            } catch (err) {
                console.log("Error in createCalls:", err);


                throw err;
            }
        },

        deleteCalls: async({dispatch}, {callId, customerId}) =>{
            try{
                await api({requiresAuth: true}).delete(
                    `v1/calls/${callId}`
                );
                await dispatch("getCalls", customerId);
            }catch(err){    
                console.log(err);
                return null;
            }
        },

        updateCalls: async(
            {dispatch},
            {
                id,
                agent_id ,
                customer_id , 
                call_category_id ,
                date ,
                time ,
                description ,
                duration_minutes 
            }
        ) => {
            try{
                await api({requiresAuth:true}).patch(
                    `v1/calls/${id}`, {
                        agent_id ,
                        customer_id , 
                        call_category_id ,
                        date ,
                        time ,
                        description ,
                        duration_minutes 
                    }
                );
                await dispatch("getCalls", customer_id);
            }catch(err){
                console.log(err);
                return err;
            }
        },

        addCallsFile: async (_, {callId, files}) => {
            const formData = new FormData();

            files.forEach((file)=>{
                formData.append("call_files[]", file);
            });
            try{
                await api({requiresAuth: true}).post(
                    `v1/calls/${callId}/files`,
                    formData,
                    {
                        headers:{
                            "Content-Type": "multipart/form-data",
                        }
                    }
                ); 
            }catch(err){
                console.log(err);
                return null;
            }
        },

        fetchCallsFiles: async ({ commit }, callId) => { 
            try {
              const response = await api({ requiresAuth: true }).get(
                `/v1/calls/${callId}`
              );
              const { data } = response.data;  
              commit("setFilesToEdit", data.files); 
              return data;
            } catch (err) {
              console.error("Error fetching note files:", err);
              return null;
            }
        },
    }
}