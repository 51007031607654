import api from "@/api";

export default{
    state:{
         

        dashboardSales: {},
    },
    getters: {
       

        dashboardSales: (state) => state.dashboardSales,
    },
    mutations: {
 
        setDashboardSales: (state, data) => {
            state.dashboardSales = data;
        },
    },
    actions:{
        getSalesDashboard: async ({commit}) => {
            try{
                const response = await api({ requiresAuth: true }).get(
                    "/v1/sales-dashboard"
                );

                const data = response;
                commit("setDashboardSales", data.data);
             
            }catch (err) {
                console.error(err);
            }
        },
    }
}